import {APP_URLS} from "./urls";

export const nav_items = [
    // {
    //     id: 'main',
    //     path: '/',
    //     title: 'Home'
    // },
    {
        id: 'boosts',
        path: APP_URLS.routes.boosts,
        title: 'Boost'
    },
    {
        id: 'task',
        path: APP_URLS.routes.tasks,
        title: 'Task'
    },
    {
        id: 'friends',
        path: APP_URLS.routes.friends,
        title: 'Friends'
    },
    {
        id: 'pte',
        path: APP_URLS.routes.p_t_e,
        title: 'PTE'
    }
]