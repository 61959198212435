export const GET_COMMUNITY_REQUEST = 'GET_COMMUNITY_REQUEST'
export const GET_COMMUNITY_SUCCESS = 'GET_COMMUNITY_SUCCESS'
export const GET_COMMUNITY_FAILURE = 'GET_COMMUNITY_FAILURE'

export const VERIFY_COMMUNITY_REQUEST = 'VERIFY_COMMUNITY_REQUEST'
export const VERIFY_COMMUNITY_SUCCESS = 'VERIFY_COMMUNITY_SUCCESS'
export const VERIFY_COMMUNITY_FAILURE = 'VERIFY_COMMUNITY_FAILURE'

export const GET_DAILY_REQUEST = 'GET_DAILY_REQUEST'
export const GET_DAILY_SUCCESS = 'GET_DAILY_SUCCESS'
export const GET_DAILY_FAILURE = 'GET_DAILY_FAILURE'

export const CHECK_DAILY_REQUEST = 'CHECK_DAILY_REQUEST'
export const CHECK_DAILY_SUCCESS = 'CHECK_DAILY_SUCCESS'
export const CHECK_DAILY_FAILURE = 'CHECK_DAILY_FAILURE'

export const SET_COMMUNITY_DEFAULT = 'SET_COMMUNITY_DEFAULT'
export const SET_DAILY_DEFAULT = 'SET_DAILY_DEFAULT'