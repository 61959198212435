import * as dashboardAction from '../actions/dashboardActions/constants'

const initialState = {
    userDashboard: null,
    isLoadingDashboard: true,
    boosts: null,
    isBoostsListLoading: true
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case dashboardAction.APPLY_BOOST_REQUEST:
            return {
                ...state,
                isBoostsListLoading: true
            };
        case dashboardAction.APPLY_BOOST_SUCCESS:
            return {
                ...state,
                boosts: action.payload,
                isBoostsListLoading: false
            };
        case dashboardAction.APPLY_BOOST_FAILURE:
            return {
                ...state,
                isBoostsListLoading: false
            };
        case dashboardAction.GET_BOOSTS_LIST_REQUEST:
            return {
                ...state,
                isBoostsListLoading: true
            };
        case dashboardAction.GET_BOOSTS_LIST_SUCCESS:
            return {
                ...state,
                boosts: action.payload,
                isBoostsListLoading: false
            };
        case dashboardAction.GET_BOOSTS_LIST_FAILURE:
            return {
                ...state,
                boosts: null,
                isBoostsListLoading: false
            };
        case dashboardAction.SET_DEFAULT_DASHBOARD:
            return {
                ...state,
                isLoadingDashboard: true,
                // userDashboard: null
            };
        case dashboardAction.GET_USER_DASHBOARD_REQUEST:
            return {
                ...state,
                isLoadingDashboard: true
            };
        case dashboardAction.GET_USER_DASHBOARD_SUCCESS:
            return {
                ...state,
                userDashboard: action.payload,
                isLoadingDashboard: false
            };
        case dashboardAction.GET_USER_DASHBOARD_FAILURE:
            return {
                ...state,
                userDashboard: null,
                isLoadingDashboard: false
            };
        case dashboardAction.CLAIM_POINTS_REQUEST:
            return {
                ...state,
                isClaimingPoints: true
            };
        case dashboardAction.CLAIM_POINTS_SUCCESS:
            return {
                ...state,
                userDashboard: action.payload,
                isClaimingPoints: false
            };
        case dashboardAction.CLAIM_POINTS_FAILURE:
            return {
                ...state,
                isClaimingPoints: false
            };
        default:
            return state
    }
}

export default dashboardReducer