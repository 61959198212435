import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {store} from '../src/store/index';
import {Provider} from "react-redux";
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

let tg = window.Telegram?.WebApp

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App
                tg={tg}
            />
        </BrowserRouter>
    </Provider>
);