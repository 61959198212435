export const GET_USER_DASHBOARD_REQUEST = 'GET_USER_DASHBOARD_REQUEST'
export const GET_USER_DASHBOARD_SUCCESS = 'GET_USER_DASHBOARD_SUCCESS'
export const GET_USER_DASHBOARD_FAILURE = 'GET_USER_DASHBOARD_FAILURE'

export const CLAIM_POINTS_REQUEST = 'CLAIM_POINTS_REQUEST'
export const CLAIM_POINTS_SUCCESS = 'CLAIM_POINTS_SUCCESS'
export const CLAIM_POINTS_FAILURE = 'CLAIM_POINTS_FAILURE'

export const GET_BOOSTS_LIST_REQUEST = 'GET_BOOSTS_LIST_REQUEST'
export const GET_BOOSTS_LIST_SUCCESS = 'GET_BOOSTS_LIST_SUCCESS'
export const GET_BOOSTS_LIST_FAILURE = 'GET_BOOSTS_LIST_FAILURE'

export const APPLY_BOOST_REQUEST = 'APPLY_BOOST_REQUEST'
export const APPLY_BOOST_SUCCESS = 'APPLY_BOOST_SUCCESS'
export const APPLY_BOOST_FAILURE = 'APPLY_BOOST_FAILURE'

export const SET_DEFAULT_DASHBOARD = 'SET_DEFAULT_DASHBOARD'