import * as pteAction from '../actions/pteActions/constants'

const initialState = {
    game: null,
    isGameLoading: true,
    isPlayGame: false,
    gamesList: null,
    isGamesLoading: true,
    hasError: false
};

const pteReducer = (state = initialState, action) => {
    switch (action.type) {
        case pteAction.GET_GAME_DATA_REQUEST:
            return {
                ...state,
                isGameLoading: true,
            };
        case pteAction.GET_GAME_DATA_SUCCESS:
            return {
                ...state,
                game: action.payload,
                isGameLoading: false
            };
        case pteAction.GET_GAME_DATA_FAILURE:
            return {
                ...state,
                game: null,
                isGameLoading: false,
                hasError: true
            };
        case pteAction.PLAY_GAME_REQUEST:
            return {
                ...state,
                isPlayGame: true,
            };
        case pteAction.PLAY_GAME_SUCCESS:
            return {
                ...state,
                isPlayGame: false
            };
        case pteAction.PLAY_GAME_FAILURE:
            return {
                ...state,
                isPlayGame: false,
                hasError: true
            };
        case pteAction.GET_GAMES_LIST_REQUEST:
            return {
                ...state,
                isGamesLoading: true
            };
        case pteAction.GET_GAMES_LIST_SUCCESS:
            return {
                ...state,
                gamesList: action.payload,
                isGamesLoading: false
            };
        case pteAction.GET_GAMES_LIST_FAILURE:
            return {
                ...state,
                gamesList: [],
                isGamesLoading: false,
                hasError: true
            };
        case pteAction.SET_GAMES_LIST_DEFAULT:
            return {
                ...state,
                isGamesLoading: true,
                hasError: false
            };
        case pteAction.SET_GAME_DEFAULT:
            return {
                ...state,
                isGamesLoading: true,
                game: null,
                hasError: false
            };
        default:
            return state
    }
}

export default pteReducer