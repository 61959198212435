import {applyMiddleware, compose, createStore,} from 'redux'
import {composeWithDevTools} from '@redux-devtools/extension'
import {thunk} from 'redux-thunk'
import {persistStore} from 'redux-persist'

import reducers from './reducers'

const debug = true;

function middleware() {
    if (debug) {
        return [
            thunk
        ]
    } else {
        return [thunk]
    }
}

let middlewares;

if (debug) {
    middlewares = composeWithDevTools(applyMiddleware(...middleware()))
} else {
    middlewares = compose(applyMiddleware(...middleware()))
}

export const store= createStore(
    reducers,
    undefined,
    middlewares
);

export const persistor = persistStore(store).subscribe(() => {

});