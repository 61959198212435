import * as tasksActions from '../actions/tasksActions/constants'

const initialState = {
    community: null,
    dailyTasks: null,
    isDailyLoading: true,
    isCommunityLoading: true,
    hasError: false
};

const pteReducer = (state = initialState, action) => {
    switch (action.type) {
        case tasksActions.VERIFY_COMMUNITY_REQUEST:
            return {
                ...state,
                isCommunityLoading: true
            };
        case tasksActions.VERIFY_COMMUNITY_SUCCESS:
            return {
                ...state,
                community: action.payload,
                isCommunityLoading: false,
                hasError: false
            };
        case tasksActions.VERIFY_COMMUNITY_FAILURE:
            return {
                ...state,
                // community: [],
                isCommunityLoading: false,
                // hasError: true
            };
        case tasksActions.GET_COMMUNITY_REQUEST:
            return {
                ...state,
                isCommunityLoading: true
            };
        case tasksActions.GET_COMMUNITY_SUCCESS:
            return {
                ...state,
                community: action.payload,
                isCommunityLoading: false,
                hasError: false
            };
        case tasksActions.GET_COMMUNITY_FAILURE:
            return {
                ...state,
                community: [],
                isCommunityLoading: false,
                hasError: true
            };
        case tasksActions.SET_COMMUNITY_DEFAULT:
            return {
                ...state,
                isCommunityLoading: true,
                hasError: false
            };
        case tasksActions.GET_DAILY_REQUEST:
            return {
                ...state,
                isDailyLoading: true
            };
        case tasksActions.GET_DAILY_SUCCESS:
            return {
                ...state,
                dailyTasks: action.payload,
                isDailyLoading: false,
                hasError: false
            };
        case tasksActions.GET_DAILY_FAILURE:
            return {
                ...state,
                dailyTasks: [],
                isDailyLoading: false,
                hasError: true
            };
        case tasksActions.SET_DAILY_DEFAULT:
            return {
                ...state,
                isDailyLoading: true,
                hasError: false,
                isDailyStreakSuccess: false
            };
        case tasksActions.CHECK_DAILY_REQUEST:
            return {
                ...state,
                isDailyLoading: true
            };
        case tasksActions.CHECK_DAILY_SUCCESS:
            return {
                ...state,
                dailyTasks: action.payload,
                isDailyLoading: false,
                isDailyStreakSuccess: true
            };
        case tasksActions.CHECK_DAILY_FAILURE:
            return {
                ...state,
                dailyTasks: [],
                isDailyLoading: false,
                hasError: true
            };
        default:
            return state
    }
}

export default pteReducer