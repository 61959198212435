export const GET_GAME_DATA_REQUEST = 'GET_GAME_DATA_REQUEST'
export const GET_GAME_DATA_SUCCESS = 'GET_GAME_DATA_SUCCESS'
export const GET_GAME_DATA_FAILURE = 'GET_GAME_DATA_FAILURE'

export const GET_GAMES_LIST_REQUEST = 'GET_GAMES_LIST_REQUEST'
export const GET_GAMES_LIST_SUCCESS = 'GET_GAMES_LIST_SUCCESS'
export const GET_GAMES_LIST_FAILURE = 'GET_GAMES_LIST_FAILURE'

export const PLAY_GAME_REQUEST = 'PLAY_GAME_REQUEST'
export const PLAY_GAME_SUCCESS = 'PLAY_GAME_SUCCESS'
export const PLAY_GAME_FAILURE = 'PLAY_GAME_FAILURE'

export const SET_GAMES_LIST_DEFAULT = 'SET_GAMES_LIST_DEFAULT'
export const SET_GAME_DEFAULT = 'SET_GAME_DEFAULT'
