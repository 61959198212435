import React from 'react';
import classNames from "classnames";

const WithLoading = (props) => {
    const {children, isLoading, dark} = props
    return (
        <>
            {isLoading ? <div className={
                classNames({
                    "loader_wrapper": true,
                    "dark": dark
                })
            }>
                <div className="lds-dual-ring"></div>
            </div> : null}
            {children}
        </>
    );
};

export default WithLoading;