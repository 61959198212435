import * as friendsAction from '../actions/friendsActions/constants'

const initialState = {
    friends: null,
    isFriendsLoading: true,
    hasError: false
};

const pteReducer = (state = initialState, action) => {
    switch (action.type) {
        case friendsAction.GET_FRIENDS_REQUEST:
            return {
                ...state,
                isFriendsLoading: true
            };
        case friendsAction.GET_FRIENDS_SUCCESS:
            return {
                ...state,
                friends: action.payload,
                isFriendsLoading: false
            };
        case friendsAction.GET_FRIENDS_FAILURE:
            return {
                ...state,
                friends: [],
                isFriendsLoading: false,
                hasError: true
            };
        case friendsAction.SET_FRIENDS_DEFAULT:
            return {
                ...state,
                isFriendsLoading: true,
                hasError: false
            };
        default:
            return state
    }
}

export default pteReducer